export type DataSourceTypeDto = {
  value:
    | "database"
    | "server"
    | "postgres"
    | "mysql"
    | "googleSheets"
    | "manual"
    | "api"
    | "file"
    | "website"
    | "notion"
    | "snowflake"
    | "mariadb"
    | "mssql"
    | "oracle"
    | "sqlite"
    | "amazonRedshift"
    | "content"
    | "hubspot";
  name: string;
  disabled?: boolean;
  underConstruction?: boolean;
  demoSlug?: string;
  accept?: { fileFormat: string; mimeType: string }[];
  demoQuestion: string;
  premium?: boolean;
  requiresCredentials: boolean;
  canSync: boolean;
};
export const DATASOURCE_DATABASE: DataSourceTypeDto = {
  value: "database",
  name: "Database",
  demoSlug: "your-database-tables",
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};
export const DATASOURCE_POSTGRES: DataSourceTypeDto = {
  value: "postgres",
  name: "PostgreSQL",
  demoSlug: "your-postgres-tables",
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};
export const DATASOURCE_MYSQL: DataSourceTypeDto = {
  value: "mysql",
  name: "MySQL",
  demoSlug: "your-mysql-tables",
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};
export const DATASOURCE_CUSTOM_SERVER: DataSourceTypeDto = {
  value: "server",
  name: "Custom Server",
  demoSlug: "your-database-in-a-custom-server",
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};
export const DATASOURCE_GOOGLE_SHEET: DataSourceTypeDto = {
  value: "googleSheets",
  name: "Google Sheets",
  disabled: false,
  underConstruction: false,
  demoSlug: "your-google-sheets",
  demoQuestion: "datalang.demos.current.defaultMessage",
  premium: true,
  requiresCredentials: false,
  canSync: true,
};
export const DATASOURCE_NOTION: DataSourceTypeDto = {
  value: "notion",
  name: "Notion",
  disabled: false,
  underConstruction: false,
  demoSlug: "your-notion-pages",
  demoQuestion: "datalang.demos.current.defaultMessage",
  premium: true,
  requiresCredentials: false,
  canSync: true,
};
export const DATASOURCE_WEBSITE: DataSourceTypeDto = {
  value: "website",
  name: "Website",
  disabled: false,
  underConstruction: false,
  demoSlug: "your-website",
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: false,
  canSync: true,
};
export const DATASOURCE_MANUAL: DataSourceTypeDto = {
  value: "manual",
  name: "Manual",
  disabled: true,
  underConstruction: true,
  demoSlug: "a-manual-table",
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: false,
  canSync: false,
};
export const DATASOURCE_API: DataSourceTypeDto = {
  value: "api",
  name: "API",
  disabled: true,
  underConstruction: true,
  demoSlug: "an-api",
  demoQuestion: "datalang.demos.current.defaultMessage",
  premium: true,
  // underConstruction: true,
  requiresCredentials: false,
  canSync: true,
};
export const DATASOURCE_FILE: DataSourceTypeDto = {
  value: "file",
  name: "Files",
  disabled: false,
  underConstruction: false,
  demoSlug: "your-files",
  demoQuestion: "datalang.demos.current.defaultMessage",
  accept: [
    { fileFormat: ".c", mimeType: "text/x-c" },
    { fileFormat: ".cs", mimeType: "text/x-csharp" },
    { fileFormat: ".cpp", mimeType: "text/x-c++" },
    { fileFormat: ".doc", mimeType: "application/msword" },
    { fileFormat: ".docx", mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
    { fileFormat: ".html", mimeType: "text/html" },
    { fileFormat: ".java", mimeType: "text/x-java" },
    { fileFormat: ".json", mimeType: "application/json" },
    { fileFormat: ".md", mimeType: "text/markdown" },
    { fileFormat: ".pdf", mimeType: "application/pdf" },
    { fileFormat: ".php", mimeType: "text/x-php" },
    { fileFormat: ".pptx", mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
    { fileFormat: ".py", mimeType: "text/x-python" },
    { fileFormat: ".py", mimeType: "text/x-script.python" },
    { fileFormat: ".rb", mimeType: "text/x-ruby" },
    { fileFormat: ".tex", mimeType: "text/x-tex" },
    { fileFormat: ".txt", mimeType: "text/plain" },
    { fileFormat: ".css", mimeType: "text/css" },
    { fileFormat: ".js", mimeType: "text/javascript" },
    { fileFormat: ".sh", mimeType: "application/x-sh" },
    { fileFormat: ".ts", mimeType: "application/typescript" },
  ],
  requiresCredentials: false,
  canSync: false,
};
export const DATASOURCE_SNOWFLAKE: DataSourceTypeDto = {
  value: "snowflake",
  name: "Snowflake",
  disabled: false,
  underConstruction: false,
  demoSlug: "your-snowflake-tables",
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};

export const DATASOURCE_MARIADB: DataSourceTypeDto = {
  value: "mariadb",
  name: "MariaDB",
  demoSlug: "your-mariadb-tables",
  underConstruction: true,
  disabled: true,
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};

export const DATASOURCE_MSSQL: DataSourceTypeDto = {
  value: "mssql",
  name: "SQL Server",
  demoSlug: "your-sql-server-tables",
  underConstruction: false,
  disabled: false,
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};

export const DATASOURCE_CONTENT: DataSourceTypeDto = {
  value: "content",
  name: "Content",
  demoSlug: "your-content",
  underConstruction: false,
  disabled: false,
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: false,
  canSync: false,
};

export const DATASOURCE_ORACLE: DataSourceTypeDto = {
  value: "oracle",
  name: "Oracle",
  demoSlug: "your-oracle-tables",
  underConstruction: true,
  disabled: true,
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};

export const DATASOURCE_SQLITE: DataSourceTypeDto = {
  value: "sqlite",
  name: "SQLite",
  demoSlug: "your-sqlite-tables",
  underConstruction: true,
  disabled: true,
  demoQuestion: "datalang.demos.current.defaultMessage",
  premium: true,
  requiresCredentials: true,
  canSync: true,
};

export const DATASOURCE_AMAZON_REDSHIFT: DataSourceTypeDto = {
  value: "amazonRedshift",
  name: "Amazon Redshift",
  demoSlug: "your-amazon-redshift-tables",
  underConstruction: true,
  disabled: true,
  demoQuestion: "datalang.demos.current.defaultMessage",
  requiresCredentials: true,
  canSync: true,
};

export const DATASOURCE_HUBSPOT: DataSourceTypeDto = {
  value: "hubspot",
  name: "HubSpot",
  demoSlug: "your-hubspot-data",
  underConstruction: false,
  disabled: false,
  demoQuestion: "datalang.demos.current.defaultMessage",
  premium: true,
  requiresCredentials: true,
  canSync: true,
};

export const DataSourceTypes: DataSourceTypeDto[] = [
  // DATASOURCE_DATABASE,
  DATASOURCE_FILE,
  DATASOURCE_POSTGRES,
  DATASOURCE_GOOGLE_SHEET,
  DATASOURCE_NOTION,
  DATASOURCE_CONTENT,
  DATASOURCE_WEBSITE,
  DATASOURCE_HUBSPOT,
  DATASOURCE_MYSQL,
  DATASOURCE_CUSTOM_SERVER,
  // DATASOURCE_MANUAL,
  DATASOURCE_SNOWFLAKE,
  DATASOURCE_MSSQL,
  DATASOURCE_API,
  DATASOURCE_MARIADB,
  DATASOURCE_ORACLE,
  DATASOURCE_AMAZON_REDSHIFT,
  DATASOURCE_SQLITE,
] as const;
export type DataSourceType = (typeof DataSourceTypes)[number]["value"];
